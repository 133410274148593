import React, { useState, useMemo, } from "react"
import {
  graphql,
  // Link,
  // navigate
} from "gatsby"
import { Layout } from "../components"
import { WhySpace, NextLaunch, Gallery, } from "../components"
import explorerImage from '../assets/images/explorer.svg'
import { get } from 'lodash'
import { DEFAULT_FILTER_TERM, MISSION_TYPE_TABS, } from '../utils/config'

export default ({ data }) => {
  const missions = data.allWpMission.nodes
  const [filterTerm, setFilterTerm] = useState(DEFAULT_FILTER_TERM)

  const filteredMissions = useMemo(() => {
    if (filterTerm === DEFAULT_FILTER_TERM) return missions
    return missions.filter((mission) => mission.mission_fields.missionType === filterTerm)
  }, [filterTerm, missions])

  const isCheckedClass = (term) => filterTerm === term ? 'is-checked' : ''

  return (
    <Layout>
      <header className="page-header">
        <div className="text-container">
          <img
            alt="logo"
            src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/space-hero.svg?1593194907"
            className="home-logo"
          />
        </div>
      </header>
      <section className="text-block-container">
        <h2>We Explore the Unknown</h2>
        <p className="subheader">
          We as a company, and as individuals, are inspired by the unknown. We
          do our best work in the unknown. SPACE is our home to experiment with
          new technologies, launch products, and have fun with our own ideas.
        </p>
        <div className="modal">
          <label htmlFor="video">
            <div className="modal-trigger link-black-base">Watch Video</div>
          </label>
          <input className="modal-state" id="video" type="checkbox" />
          <div className="modal-fade-screen">
            <label className="modal-close" htmlFor="video">
              <img
                src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/close.svg?1593194907"
                alt="close video"
              />
            </label>
            <div className="modal-inner">
              <div className="modal-content" id="space-video">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/z07whuXTnOA?enablejsapi=1"
                  frameBorder="0"
                  allowFullScreen
                  title="SPACE Highlights Video"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="filter-wrapper">
          <div className="button-group filters-button-group filter-container">
            {MISSION_TYPE_TABS.map(({ type, title, description, }) => {
              return (
                <button
                  className={ `button1 ${isCheckedClass(type)}` }
                  type="button"
                  onClick={ () => {
                    setFilterTerm(type)
                  }}
                >
                  <h4 className="underline-header">{title}</h4>
                  <p>{description}</p>
                </button>
              )
            })}
          </div>
        </div>
        <ul className="grid grid-container">
          {filteredMissions.map(({ slug, title, mission_fields: { teaser, missionPatch, missionType, }, }) => {
            const patchImage = get(missionPatch, 'mediaItemUrl', explorerImage)
            return (
              <li
                key={slug}
                className={`element-item ${missionType}`}
              >
                <a href={`/missions/${slug}`}>
                  <img
                    src={ patchImage }
                    alt="mission-patch"
                    className="preview-badge"
                  />
                  <div className="preview-text">
                    <h5>{title}</h5>
                    <p>{teaser}</p>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </section>
      <NextLaunch title="Demo Day In" />
      <Gallery />
      <WhySpace />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpMission(filter: {mission_fields: {featured: {eq: "Y"}}}) {
      nodes {
        slug
        title
        mission_fields {
          missionPatch {
            altText
            mediaItemUrl
          }
          missionType
          teaser
        }
      }
    }
  }
`
